@import 'src/style/mixins';

.LoginContentUpdate {
  position: absolute;
  top: 0px;
  z-index: 11;
  height: 100%;
  width: 100%;
  @include FlexCenterCenter;

  &.fixed {
    position: fixed;
    height: 100vh;
  }
}

.analyze {
  backdrop-filter: blur(2.5px);
  background-color: rgba(0, 0, 0, 0.3);
}

.score,
.competitors {
  background-position-y: 66px;
  @include tablet {
    background-position-y: 82px;
  }
}

.score {
  background-image: url('/images/backgrounds/score.jpg');
}
.competitors {
  background-image: url('/images/backgrounds/competitors.jpg');
}
