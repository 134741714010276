@import 'src/style/mixins';

.Container {
  list-style: none;
  padding: 1px 0 4px;
  color: $color-black3;
  margin-bottom: 2px;
  margin-top: 2px;
  border: 1px solid #e9eaed;

  li > div {
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: 20% 80%;
    align-self: center;
    align-items: center;
    min-height: 40px;
    background-color: $color-white;
    font-size: 13px;
  }

  li:hover,
  &:focus {
    text-decoration: none;
    color: $color-blue2;
    background-color: $color-blue3;
    cursor: pointer;
  }
}

.Uppercase {
  li > div {
    text-transform: uppercase;
  }
}

.ItemIcon {
  justify-self: center;
}

.CurrencySelectorDiv {
  min-width: 200px;
}

.CurrencySelectorSpan {
  margin-left: 10px;
  white-space: nowrap;
}
