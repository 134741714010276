@import 'src/style/mixins';

.Small {
  max-height: 100%;

  .Fill {
    fill: $color-black3;
  }

  .Stroke {
    stroke: $color-black3;
  }

  &.SmallBlue  {
    .Fill {
      fill: $color-blue1;
    }

    .Stroke {
      stroke: $color-blue1;
    }
  }
}
