@import 'src/style/mixins';

.ModalContainer {
  position: fixed;
  z-index: 30;
}

.ModalBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
}

.ModalContainer,
.ModalBackground {
  left: 0;
  bottom: 0;
  width: 100%;
}

.Box {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  transition: all ease-in-out 250ms;
  border-radius: 0;
  max-width: none;

  &.FadeInAnimation {
    opacity: 0;
    transform: scale(1.05);

    &.Active {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.Content {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding-bottom: 40%;
  padding-left: 10px;
  padding-top: 10px;
}

