@import 'src/style/mixins';

.LoginWizardContainer {
  max-height: calc(100vh - 65px);
  overflow-y: auto;
  max-width: 550px;
}

.Profiles {
  max-width: unset;
  padding: 24px 32px;
}
