@import 'src/style/mixins';

.Info {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  @include tablet {
    > svg {
      width: 9px;
      height: 9px;
    }
  }

  .InfoIconBackground {
    fill: $color-black4;
  }
  .InfoIconLetter {
    stroke: $color-black1;
  }
}

.InfoActivated {
  .InfoIconBackground {
    fill: $color-black1;
  }
  .InfoIconLetter {
    stroke: $color-white;
  }
}

.Container {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  &:hover {
    .InfoIconBackground {
      fill: $color-black1;
    }
    .InfoIconLetter {
      stroke: $color-white;
    }
  }
}

.DropdownBox {
  font-size: 12px;
}

.Title {
  font-weight: $fontWeights-semiBold;
}

.CallToActionButton {
  float: right;
  margin-top: 6px;
}

.Dotten {
  font-size: 12px;
  color: #525252;
  font-weight: $fontWeights-regular;
  text-decoration: underline dotted;
  width: 100%;
}
