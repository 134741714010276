@import 'src/style/mixins';

.Icon {
  min-width: 17px;
}

.stroke {
  stroke: $color-black3;
}

.fill {
  fill: $color-black3;
}
