@import 'src/style/mixins';

.Container {
  display: inline-block;
  list-style: none;
  padding-left: 11px;
  position: fixed;
  right: 11px;
  top: 80px;
  max-height: calc(100vh - 82px);
  overflow-y: auto;
  z-index: 19;

  &.BottomLeft {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    max-width: 420px;
  }

  @include tablet {
    padding-left: 105px;
  }

  @include desktop {
    padding-left: 24px;
  }
}

.Notification {
  display: flex;
}

.Item {
  margin-bottom: 10px;
}
