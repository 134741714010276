@import 'src/style/mixins';

.EmailLoginStatusContainer {
  text-align: center;
  padding: 80px 36px;

  @include tablet {
    padding: 104px 132px 80px 132px;
    min-width: 310px;
  }

  .MainText {
    font-size: 14px;
    font-weight: $fontWeights-light;
    max-width: 208px;
    margin: 20px auto 65px auto;

    @include tablet {
      font-size: 16px;
      max-width: 310px;
      margin-bottom: 61px;
    }
  }

  .EmailTitle {
    font-weight: $fontWeights-semiBold;
    margin-top: 17px;
  }

  .Email {
    font-weight: $fontWeights-semiBold;
  }

  .SecondTextParagraph {
    display: inline-block;
    max-width: 180px;
    text-align: justify;
    text-justify: inter-word;
    @include tablet {
      max-width: none;
    }
  }
}

.GrayText {
  color: $color-black3;
  margin-bottom: 15px;

  @include tablet {
    margin-bottom: 5px;
  }
}

.BackToLogin {
  &:hover {
    text-decoration: none;
  }
}