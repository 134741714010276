@import 'src/style/mixins';

.Container {
  display: inline-flex;
  align-items: center;
  height: 40px;
  border: 1px solid $color-black5;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 2px 2px 10px $color-shadows;
  transition: box-shadow ease-in-out 150ms, border-color ease-in 150ms;

  &.Locked {
    .ChevronContainer {
      display: none;
    }

    .LockedIcon {
      display: block;
    }

    &:hover {
      .Label {
        color: $color-disabled;
      }
    }
  }

  .Selected {
    color: $color-blue1;
  }

  .ChevronAndTextContainer {
    display: flex;
    padding: 0 4px;
    min-width: 110px;
  }

  .ChevronText {
    color: $color-blue2;
    font-weight: $fontWeights-light;
    padding-right: 6px;
    font-size: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;

    @include tablet {
      font-size: 12px;
    }
  }

  &.HasChanges {
    border-color: $color-borders-active;
    box-shadow: 0px 5px 5px $color-shadows-active;
    .ChevronContainer path {
      fill: $color-borders-active;
    }
    .Label {
      color: $color-blue1;
    }
    &:hover {
      background-color: $color-blue3;
      .ChevronContainer {
        background-color: transparent;
      }
      .Label {
        color: $color-blue1;
      }
    }
  }

  &.Active {
    border-color: $color-borders-active;
    background-color: $color-blue1;
    .ChevronContainer path {
      fill: $color-white;
    }
    .Label {
      color: $color-white;
    }
    &:hover {
      background-color: $color-blue1;
      .ChevronContainer {
        background-color: transparent;
      }
      .Label {
        color: $color-white;
      }
    }
  }

  &:hover {
    text-decoration: none;

    .Label {
      color: $color-black0;
    }
  }

  // styles to themes
  &.GrayTheme {
    height: 55px;

    .Label {
      color: $color-black0;
      font-weight: $fontWeights-semiBold;
      width: calc(100% - 32px);
    }

    &.Active {
      background-color: $color-white;
      border-color: $color-black5;

      .Label {
        color: $color-black0;
      }

      .ChevronContainer path {
        fill: $color-black0;
      }
    }
  }

  &.SmallGrayTheme {
    height: 35px;
    width: 93px;

    .Label {
      color: $color-black0;
      font-size: 14px;
      font-weight: $fontWeights-regular;
      margin: 0;
    }

    .Content {
      display: flex;
      justify-content: space-between;
      padding-left: 12px;
      padding-right: 8.6px;

      .ChevronContainer {
        display: contents;
        justify-content: space-between;
        width: 11.55px;
        height: 11.55px;
        path {
          fill: $color-black4;
        }

        &.hide {
          display: none;
        }
      }

      .LockedIcon {
        transform: scale(0.7);
        margin: 0;
      }
    }

    &.Active {
      background-color: $color-white;
      border-color: $color-black5;

      .Label {
        color: $color-black0;
      }

      .ChevronContainer path {
        fill: $color-black4;
      }
    }
  }

  &.CustomGraySize {
    background-color: $color-black7;
    height: 31px;

    .Label {
      color: black;
      font-size: 16px;
      font-weight: $fontWeights-semiBold;
      margin: 0 10px 0 14px;
    }

    &.Active {
      background-color: $color-black5;
      border-color: $color-black4;

      .Label {
        color: $color-black3;
      }
      .ChevronContainer path {
        margin-left: 2px;
        fill: $color-black2;
      }
    }

    .ChevronContainer {
      display: flex;
      path {
        fill: $color-black2;
      }
    }
  }

  &.CustomTheme {
    font-size: 14px;
    &.Active {
      background-color: $color-white;
      border-color: $color-black4;

      .Label {
        color: $color-black3;
      }
      .ChevronContainer path {
        fill: $color-black4;
      }
    }
    &:hover {
      .Label {
        color: unset;
      }
    }
    .ChevronContainer {
      display: block;
    }

    .ChevronContainer path {
      fill: $color-black4;
    }
  }

  &.WhiteTheme {
    font-size: 13px;
    .Label {
      color: $color-black0;
      font-size: 14px;
      font-weight: $fontWeights-regular;
    }
    &:hover {
      .Label {
        color: $color-blue2;
      }
    }
  }

  &.LightGrayTheme {
    height: 41px;
    width: 41px;

    .Label {
      color: $color-blue2;
      font-size: 14px;
      margin: auto;
    }

    &.Active {
      background-color: $color-black6;
      border-color: $color-black5;

      .Label {
        color: $color-blue2;
      }
    }

    .ChevronContainer {
      display: none;
    }
  }

  &.HelpMenuLightGrayTheme {
    height: 29px;
    width: 41px;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 14px;
    margin: 0;
    border-radius: $borderRadius;
    @include tablet {
      width: 50px;
    }

    transition: all 0.15s;
    border: 1px solid transparent;

    &:hover {
      background-color: $color-black4;
      border: 1px solid $color-black3;

      .Label {
        color: $color-black0;
      }
    }

    .Label {
      color: $color-black0;
      font-size: 14px;
      margin: auto;
      font-weight: 100;
    }

    &.Active {
      background-color: $color-black4;
    }

    .ChevronContainer {
      display: none;
    }
  }

  &.LightGrayTextTheme {
    .Label {
      color: $color-black0;
      font-size: 14px;
      font-weight: $fontWeights-regular;
    }

    &.Active {
      background-color: $color-white;
      border-color: $color-black4;

      .Label {
        color: $color-black3;
      }
      .ChevronContainer path {
        fill: $color-black4;
      }
    }

    .ChevronContainer {
      display: none;

      @include tablet {
        display: flex;
      }
      &.hide {
        display: none;
      }
    }

    .ChevronContainer path {
      fill: $color-black4;
    }
  }

  &.Inverted {
    height: 37px;
    background-color: $color-white;
    border-color: $color-blue1;

    .Label {
      color: $color-blue1;
    }

    &.Active {
      background-color: $color-white;
      border-color: $color-blue1;

      .Label {
        color: $color-blue1;
      }
    }
  }

  &.CollapseMobile {
    display: inline-flex;
    height: 45px;
    width: 45px;
    border-color: $color-black5;

    &.HasChanges {
      border-color: $color-blue1;

      .IconContainer {
        svg {
          g {
            stroke: $color-blue1;
          }
        }
      }

      .ChevronContainer {
        path {
          fill: $color-blue1;
        }
      }

      .Label {
        color: $color-blue1;
      }
    }

    &.Active {
      .Label {
        color: $color-white;
      }

      .ChevronContainer {
        path {
          fill: $color-white;
        }
      }

      .IconContainer {
        svg {
          g {
            stroke: $color-white;
          }
        }
      }
    }

    &.Locked {
      .LockedIcon {
        display: none;
      }
    }

    @include desktop {
      width: auto;

      .ChevronContainer {
        box-sizing: content-box;
        display: block;
        height: 4px;
        padding: 0 8px 0 5px;
        width: 6px;
      }

      .Label {
        color: $color-black3;
        font-size: 14px;
        font-weight: $fontWeights-regular;
        margin: 0 0 0 8px;
        transition: color ease-in-out 150ms;
      }

      .ChevronContainer {
        path {
          fill: $color-black3;
        }
      }
    }

    .IconContainer {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      svg {
        height: 18px;
        width: 18px;

        g {
          stroke: $color-black3;
        }
      }

      & ~ .Content {
        display: none;
      }

      @include desktop {
        display: none;

        & ~ .Content {
          display: flex;
        }
      }
    }
  }
  &.CustomLabel {
    padding: 10px;
    border: 1px solid $color-black5;
    line-height: 0px;
    height: 40px;
  }
}

.Label {
  color: $color-disabled;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  margin: 0 14px;

  @include desktop {
    font-size: 14px;
  }
}

.LockedIcon {
  display: none;
  margin-left: 14px;
}

.ChevronContainer {
  align-items: center;
  border-radius: 0 $borderRadius $borderRadius 0;
  display: none;
  width: 22px;
  height: 100%;

  &.hide {
    display: none;
  }

  @include desktop {
    display: flex;

    &.hide {
      display: none;
    }
  }
}

.Image {
  height: 18px;
  width: 18px;
}

.IconContainer {
  display: none;
}

.Content {
  align-items: center;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.Container:hover {
  background-color: #f6f7f9;
}

.BlueTheme {
  background-color: $color-blue2;
  border-radius: $borderRadius;
  height: 32px;
  color: $color-white;
  font-size: 14px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-blue1;
      color: $color-white;
    }
  }
  .Label {
    color: $color-white;
    margin: 0;
    font-size: 14px;
  }
  &:hover {
    .Label {
      color: $color-white;
      font-size: 14px;
    }
  }
}

.WhiteBlueTheme {
  border: none;
  height: 48px;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;

  .Content > .Label {
    color: $color-black8;
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Content {
    height: 100%;
  }

  &:hover {
    background-color: transparent;
  }

  &.Active {
    background-color: transparent !important;
    color: red;
    border-color: $color-black3;

    .Content > .Label {
      color: $color-black8;
    }
  }
}