@import 'src/style/mixins';

.DropdownMenu {
  display: inline-block;
  position: relative;
}

.DropdownBox {
  min-width: 100%;
}
