@import 'src/style/mixins';

.CloseButton {
  display: block;
  width: 25px;
  height: 25px;
  padding: 5px;
  &:hover .CloseIcon {
    &::before, &::after {
      background: $color-pred;
    }
    &::before {
      transform: rotate(135deg);
    }
    &::after {
      transform: rotate(225deg);
    }
  }
}

.CloseIcon {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: $color-black2;
    transition: background-color 0.2s, transform 0.3s ease-out;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(135deg);
  }
}