@import 'src/style/mixins';

.ContainerList {
  width: 182px;
}

.SideMenu {
  &[class^='DropdownButton_Container_'] {
    border: 1px solid $color-blue1;
  }

  [class^='DropdownButton_Content'] {
    [class^='DropdownButton_Label'] {
      color: $color-blue1;
    }
  }
}
