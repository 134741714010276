@import 'src/style/mixins';

.DropdownBoxContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 50;

  &.DropdownBoxContainerFixed {
    position: fixed;
  }
}

div.DropdownBox {
  position: absolute;
  z-index: 20;
  margin: 3px 0 0;
  pointer-events: all;
}

.Arrow,
.Arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 11px;
  border-color: transparent;
  border-style: solid;
}

.Arrow::after {
  content: '';
  border-width: 10px;
}

.DropdownBox.default {
  overflow: hidden;
}

.DropdownBox.top {
  bottom: 100%;
  margin: 0 0 11px 0;
  .Arrow {
    top: 99%;
    left: 50%;
    transform: rotate(180deg);
    margin-left: -11px;
    border-top-width: 0;

    &::after {
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }
}

.DropdownBox.topRight {
  bottom: 100%;
  margin: 0 0 0 -145px;

  @include tablet {
    margin: 0 0 0 30px;
  }

  .Arrow {
    top: 99%;
    left: 50%;
    transform: rotate(180deg);
    margin-left: -11px;
    border-top-width: 0;

    &::after {
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }
}

.DropdownBox.bottom {
  top: 100%;
  margin: 11px 0 0 0;
  .Arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;

    &::after {
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }
}

.DropdownBox.left {
  right: 100%;
  margin: 0 16px 0 0;
  .Arrow {
    right: -16px;
    transform: rotate(90deg);
    border-top-width: 0;

    &::after {
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }
}

.DropdownBox.right {
  left: 100%;
  margin-left: 16px;
  margin: 0 0 0 16px;
  .Arrow {
    left: -5px;
    transform: rotate(270deg);
    margin-left: -11px;
    border-top-width: 0;

    &::after {
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }
}

.DropdownBox.top,
.DropdownBox.topRight,
.DropdownBox.bottom,
.DropdownBox.right,
.DropdownBox.left {
  display: flex;
  align-items: center;
  .Arrow {
    border-bottom-color: $color-borders;

    &::after {
      border-bottom-color: $color-white;
    }
  }
}

.DropdownBox.bottomNoArrow {
  margin-top: 0;
}

.DropdownBox.topNoArrow {
  bottom: 100%;
  margin-bottom: 0;
}

.DropdownBox.DropdownPercentage {
  margin-left: 100%;
}

.DarkTheme {
  background-color: $color-black1;
  color: $color-white;
}

.DarkTheme.default,
.DarkTheme.top,
.DarkTheme.topRight,
.DarkTheme.bottom,
.DarkTheme.right,
.DarkTheme.left {
  border: 1px solid $color-black1;
  .Arrow {
    border-bottom-color: $color-black1;

    &::after {
      border-bottom-color: $color-black1;
    }
  }
}
