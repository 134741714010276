@import 'src/style/mixins';

$closeSize: 22px;
$colorGreen: #6fdc94;

.Container {
  background: $color-white;
  border-radius: $borderRadius;
  box-shadow: 0 3px 6px rgba($color-black0, 0.3);
  padding: 19px 8px 19px 22px;
  position: relative;
  max-width: 600px;

  &:after {
    border-radius: 4px;
    content: ' ';
    height: 80%;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
  }

  @include tablet {
    padding: 19px 60px 19px 22px;
  }
}

.ContainerHideButton {
  padding: 20px 0 20px 20px;
  @include tablet {
    padding: 18px 18px 18px 25px;
  }
}

.Title {
  font-size: 14px;
  font-weight: $fontWeights-semiBold;
}

.Description {
  font-size: 12px;
  color: $color-black3;
  width: 90%;
  @include tablet {
    width: auto;
  }
}

.Close {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: $closeSize;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  transition: box-shadow ease-in-out 150ms;
  width: $closeSize;

  @include tablet {
    right: 19px;
  }
}

@mixin updateItemsByType($color) {
  &:after {
    background-color: $color;
  }

  .Close {
    background: rgba($color, 0.1);

    &:hover {
      box-shadow: 0 3px 6px rgba($color, 0.3);
    }
  }

  .CloseButton {
    span {
      &:before,
      &:after {
        background-color: $color;
      }
    }

    &:hover {
      span {
        &:before,
        &:after {
          background-color: $color;
        }
      }
    }
  }
}

.error {
  @include updateItemsByType($color-pred);
}

.warning {
  @include updateItemsByType($color-pyellow);
}

.info {
  @include updateItemsByType($color-pblue);
}

.success {
  @include updateItemsByType($colorGreen);
}
