@import 'src/style/mixins';

.ProfilesSelector {
  $vertical-margin: 20px;
  width: 320px;

  @include tablet {
    padding: 20px;
    width: 700px;
  }

  @include desktop {
    display: none;
  }

  .Header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }

  .UserTypeOptions {
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
    line-height: 200%;
    padding: 4px 0;

    a {
      margin: 5px;
    }
  }

  .ErrorMessage {
    margin-bottom: $vertical-margin;
    color: $color-darkred;

    img {
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .Button {
    font-size: 14px;
    font-weight: $fontWeights-semiBold;
    border-radius: 4px;
    float: right;
    margin-bottom: 20px;
  }

  a.Button.Disable {
    &:hover {
      cursor: not-allowed;
      background-color: $color-black5;
      color: $color-black3;
    }
  }
}
