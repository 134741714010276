@import 'src/style/mixins';

.UserMenu {
  @include FlexCenterCenter;
  border: 1px solid $color-black6;
  border-radius: $borderRadius;
  white-space: nowrap;
  font-weight: $fontWeights-light;
  font-size: 13px;
  min-height: 30px;
  padding: 0 5px;
  transition: all 0.15s;
  border: 1px solid transparent;
  margin-left: 5px;

  &:hover,
  &.Active {
    background-color: $color-black4;
  }

  &:hover {
    border: 1px solid $color-black3;
  }

  &.active,
  &:focus {
    background-color: $color-black4;
    border: 1px solid $color-black3;
  }

  @include desktop {
    margin-left: 0;
  }
}

.Chevron {
  display: none;
}

.UserIcon {
  width: 14px;
  path {
    stroke: $color-blue2;
  }
  @include desktop {
    display: block;
  }
}

.UserInfo {
  padding: 2px 19px 7px;
  border-bottom: 1px solid $color-black5;
}

.UserName {
  color: $color-black0;
  font-weight: $fontWeights-medium;
  line-height: 21px;
  gap: 2px;
  margin-bottom: 4px;
  display: grid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .Name {
    @include ellipsis;
  }

  .Email {
    color: $color-black2;
    @include ellipsis;
    @include ellipsis;
  }
}

.UserType {
  color: $color-blue1;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    text-decoration: none;
  }
}

.UserMenuLogin {
  .UserIcon {
    display: block;
  }

  .SignInLabel {
    display: none;
  }

  .Chevron {
    display: block;
  }
}

.SignInLabel {
  font-size: 14px;
}

.UserMenuContainer {
  position: relative;
}

.Menu {
  width: 196px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  list-style: none;
  li > a {
    display: flex;
    align-items: center;
    min-height: 44px;
    color: $color-black3;
    padding: 11px 15px;
    svg {
      margin-right: 13px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-blue2;
      background-color: $color-blue3;

      .stroke {
        stroke: $color-blue2;
      }

      .fill {
        fill: $color-blue2;
      }
    }
  }
  @include desktop {
    width: 100%;
  }
}

.fill {
  fill: $color-black3;
}
.MenuContainer {
  right: 0;
  border: 1px solid #e9eaed;

  @include desktop {
    width: 222px;
    height: 48px;
  }
}

.IconContainer {
  margin-right: 7px;
  position: relative;
}

.PremiumBadge {
  bottom: -3px;
  position: absolute;
  right: 0;
  transform: translate(70%);
  width: 10px;
}

.SymbolCurrency {
  font-weight: $fontWeights-semiBold;
  min-width: 17px;
  margin-right: 13px;
  text-align: center;
}

.UserMenuLogOut {
  background-color: $color-blue2;
  border-radius: 4px;
  height: 28px;
  padding: 0 24px;
  color: $color-white;
  font-weight: 600;
  width: 80px;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $color-blue1;
    color: $color-white;
    text-decoration: none;
    border: 1px solid $color-blue1;
  }
}

.NoBadge {
  margin-left: 7px;
}

.CreditCardIcon {
  path {
    @extend .fill;
  }
}
