@import 'src/style/mixins';

.Container {
  display: none;

  @include desktop {
    display: block;
    .Header {
      margin: 0 auto;
      text-align: center;
      margin-bottom: 16px;
    }
    .Items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      cursor: pointer;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-direction: column;
        width: 200px;
        height: 200px;
        text-align: center;
        padding: 16px;
        border: 1px solid $color-black5;
        img {
          width: 60px;
          height: 50px;
        }
        &:hover {
          background-color: #eff1f4;
        }
      }
    }
    .Footer {
      margin-top: 16px;
      text-align: center;
      text-decoration: underline;
      color: $color-blue2;
      cursor: pointer;
    }
  }
}
