@import 'src/style/mixins';

$normalSize: 45px;
$mediumSize: 36px;
$smallSize: 24px;

// Boxes
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0 none;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.3s ease-out;

  &:focus {
    outline: 0 none;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background: $color-black5 !important;
    cursor: default !important;
  }

  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      text-decoration: none;
    }
  }

  .ButtonContent {
    display: inline-block;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }
}

.Logo {
  vertical-align: middle;
}

.LockedIcon {
  vertical-align: inherit;
}

// theme
.DefaultTheme {
  background-color: $color-blue2;
  border-radius: $borderRadius;
  height: $normalSize;
  padding: 0 24px;
  color: $color-white;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-blue1;
      color: $color-white;
    }
  }
}

.ButtonSmall {
  height: $button_height_sm;
  padding: 1px 10px;
}

.ButtonMedium {
  height: $button_height_md;
  padding: 2px 12px;
}

.ButtonLarge {
  height: $button_height_lg;
  padding: 4px 15px;
}

.BlueLineTheme {
  border: 2px solid $color-blue2;
  border-radius: $borderRadius;
  height: $normalSize;
  padding: 0 24px;
  color: $color-blue2;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-blue2;
      color: $color-white;
    }
  }
}

.GreenTheme {
  background-color: $color-positivegreen;
  border: 2px solid $color-positivegreen;
  border-radius: $borderRadius;
  height: $normalSize;
  padding: 0 24px;
  color: $color-white;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-positivegreen;
      opacity: 0.7;
    }
  }
  &.Disabled {
    pointer-events: none;
    background-color: $color-black5;
    color: $color-white;
    border: 2px solid $color-black6;
  }
}

.MintGreenTheme {
  background-color: $color-mintgreen;
  border-radius: $normalSize;
  height: $normalSize;
  padding: 7px 36px;
  color: $color-white;
  font-size: 16px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-mintgreen;
      color: $color-white;
    }
  }
}

.YellowTheme {
  background-color: $color-yellow1;
  border-radius: $normalSize;
  height: $normalSize;
  padding: 7px 36px;
  color: $color-white;
  font-size: 16px;
  font-weight: $fontWeights-semiBold;
  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-orange;
    }
  }
}

.YellowGradientTheme {
  background: linear-gradient(135deg, $color-yellow1 25%, $color-orange 70%);
  background-size: 200% 200%;
  background-position: 0% 0%;
  transition: background-position 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: $normalSize;
  height: $normalSize;
  padding: 7px 36px;
  color: $color-white;
  font-size: 16px;
  font-weight: $fontWeights-semiBold;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-position: 40% 40%;
  }
}

.LightBlueTheme {
  background-color: $color-blue3;
  border-radius: $borderRadius;
  border: 1px solid $color-blue3;
  height: $mediumSize;
  padding: 0 12px;
  color: $color-blue2;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;
  transition: border-color 0.15s;

  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-blue3;

      color: $color-blue2;
      border: 1px solid $color-blue2;
    }
  }
}

.BlueTheme {
  background-color: $color-blue2;
  border-radius: $borderRadius;
  border: 1px solid $color-blue1;
  height: $mediumSize;
  padding: 0 12px;
  color: $color-white;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;

  @include tablet {
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-blue3;

      color: $color-blue2;
      border: 1px solid $color-blue2;
    }
  }
}

.WhiteTheme {
  background-color: $color-white;
  border-radius: $mediumSize;
  height: $mediumSize;
  padding: 0 24px;
  color: $color-blue1;
  font-size: 12px;
  border: 1px solid $color-black5;
  @include tablet {
    &:hover,
    &:focus {
      background-color: $color-blue3;
      border-color: $color-blue3;
    }
  }
}

.TransparentTheme {
  background-color: transparent;
  border-radius: $smallSize;
  height: $smallSize;
  padding: 0 15px 0 12px;
  color: $color-blue2;
  font-size: 12px;
  @include tablet {
    &:hover,
    &:focus {
      background-color: $color-black6;
    }
  }

  .Logo {
    width: 20px;
  }

  &.Locked {
    background: transparent;
    color: $color-black3;
    align-items: center;

    @include tablet {
      &:hover,
      &:focus {
        color: $color-blue2;
        background-color: $color-black6;
        .LockedIcon {
          stroke: $color-blue2;
        }
      }
    }

    .LockedIcon {
      display: inline-flex;
      margin-right: 7px;
      stroke: $color-black3;
    }
  }

  &.Loading {
    background-color: $color-black5;
    @include tablet {
      &:hover,
      &:focus {
        cursor: default;
        background-color: $color-black5;
      }
    }
  }
  &.Disabled {
    color: $color-black3;
  }
}

.TransparentBorderWhiteTheme {
  border: 1px solid $color-white;
  background: transparent;
  border-radius: $mediumSize;
  height: $mediumSize;
  padding: 0 15px 0 12px;
  color: $color-white;
  font-size: 12px;

  @include tablet {
    &:hover,
    &:focus {
      background-color: $color-black6;
    }
  }

  &.Locked {
    background: transparent;
    align-items: center;

    @include tablet {
      &:hover,
      &:focus {
        background-color: $color-black6;
      }
    }

    .LockedIcon {
      display: inline-flex;
      margin-right: 7px;
    }
  }
}

.RedTheme {
  background-color: $color-pred;
  border-radius: $mediumSize;
  color: $color-white;
  font-size: 18px;
  height: $normalSize;
  padding: 0 24px;
  font-weight: $fontWeights-semiBold;
}

.GrayTheme {
  background-color: transparent;
  border: 1px solid $color-black5;
  border-radius: $borderRadius;
  height: $normalSize;
  padding: 0 24px;
  color: $color-black0;
  font-size: 12px;
  font-weight: $fontWeights-medium;
  &:hover {
    background-color: $color-black5;
  }
}

// Modifiers
.Locked {
  background-color: $color-black5;
  color: $color-black3;

  &:hover {
    background-color: $color-black5;
    color: $color-black3;
  }
}

.LockedIcon {
  display: none;
  stroke: $color-blue2;
}
