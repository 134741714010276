@import 'src/style/mixins';

.Container {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.Disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.Input {
  display: none;
}

.Circle {
  align-items: center;
  background-color: transparent;
  border: 2px solid $color-black4;
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  height: 17px;
  left: 0;
  position: absolute;
  width: 17px;
  top: 50%;
  transform: translateY(-50%);
  transition: border ease-in-out 150ms, background-color;

  &.active {
    background-color: $color-blue2;
    border-color: $color-blue2;
  }

  &:hover:not(.active) {
    background-color: $color-black5;
  }

  &.CircleDisabled {
    &:hover:not(.active) {
      background-color: transparent;
    }
  }
}

.ThemeBlue {
  &.active {
    background-color: $color-blue2;
    border-color: $color-blue2;
  }
}

.ThemeRed {
  &.active {
    background-color: $color-pred;
    border-color: $color-pred;
  }
}

.ThemeYellow {
  &.active {
    background-color: $color-pyellow;
    border-color: $color-pyellow;
  }
}

.ThemeMint {
  &.active {
    background-color: $color-mint;
    border-color: $color-mint;
  }
}

.ThemeLavanda {
  &.active {
    background-color: $color-lavanda;
    border-color: $color-lavanda;
  }
}

.ThemeOrange {
  &.active {
    background-color: $color-orange;
    border-color: $color-orange;
  }
}

.ThemePink {
  &.active {
    background-color: $color-pink;
    border-color: $color-pink;
  }
}

.ThemePlatano {
  &.active {
    background-color: $color-platano;
    border-color: $color-platano;
  }
}

.CircleSmall {
  height: 10px;
  width: 10px;
}

.Text {
  color: $color-black3;
  font-size: 12px;
  font-weight: $fontWeights-regular;
  padding-left: 27px;
}

.Point {
  background-color: $color-white;
  border-radius: 50%;
  height: 7px;
  width: 7px;
}

.PointSmall {
  height: 6px;
  width: 6px;
}

.BoldLabelTheme {
  .Text {
    font-size: 12px;
    font-weight: $fontWeights-regular;
    line-height: 18px;
  }
}

.DefaultTheme {
  .Text {
    font-size: 14px;
    font-weight: $fontWeights-regular;
    line-height: 21px;
    color: $color-black0;
  }
}