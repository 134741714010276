@import 'src/style/mixins';

.Item {
  padding: 2px 15px;
  border-radius: $borderRadius;
  font-weight: 100;
  cursor: pointer;
  transition: all 0.15s;
  border: 1px solid transparent;
  box-sizing: border-box;
  height: 28px;

  &:hover {
    background-color: $color-black4;
    border: 1px solid $color-black3;
  }

  &.Active {
    background-color: $color-black4;
  }
}
