@import 'src/style/mixins';

// Boxes
.Box {
  position: relative;
  background-color: $color-white;
  border-radius: $borderRadius;
}

// themes
.BoxStyleBig {
  padding: 16px 24px;
}

.BoxStyleSmall {
  padding: 12px 21px;
}
