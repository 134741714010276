@import 'src/style/mixins';

.Chevron {
  display: block;
  position: relative;
  transform: rotateZ(0deg);
  transition: transform ease-in 150ms;
}

.Smaller {
  width: 10px;
}

.Bigger {
  width: 14px;
}

.Micro {
  width: 6px;
}

.ExtraLarge {
  width: 26px;
}

.Chevron_Active {
  transform: rotateZ(180deg);
}

.Triangle {
  &.Micro {
    border: 3px solid transparent;
    border-bottom: none;
    border-top-color: $color-black0;
  }

  &.Smaller {
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: $color-black0;
  }

  &.Bigger {
    border: 7px solid transparent;
    border-bottom: none;
    border-top-color: $color-black0;
  }

  &.ExtraLarge {
    border: 1px solid transparent;
    border-bottom: none;
    border-top-color: $color-black0;
  }
}
